import { Menu as MenuAntd, Dropdown, Avatar, Divider } from 'antd';

import { Container, MenuMobile, Profile } from './styles';
import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import {
  CloseOutlined,
  EditOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MenuOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import BaseOndas from '../../assets/bg-bottom-menu.svg';
import logoBrasao from '../../assets/governo-do-estado-do-ceara-logo.png';
import { Link, useNavigate } from 'react-router-dom';

export default function Header({ isLogged }: { isLogged: boolean }) {
  const { signOut, client } = useAuth();
  const { name, image } = client || {
    name: null,
    image: '',
  };
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);

  let nome = name;

  let user = nome;

  const arrayNome = name
    ?.split(' ')
    .map(n => n.charAt(0).toUpperCase() + n.slice(1).toLowerCase());

  if (arrayNome && arrayNome?.length > 1) {
    nome = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;

    user = `${arrayNome[0].substr(0, 1)}${arrayNome[
      arrayNome.length - 1
    ].substr(0, 1)}`;
  }

  const redirectToHome = () => {
    return isLogged ? navigate('/home') : navigate('login');
  };

  const menu = (
    <MenuAntd>
      <MenuAntd.Item key="1" onClick={() => signOut()}>
        <span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <LogoutOutlined />
          Sair
        </span>
      </MenuAntd.Item>
      <MenuAntd.Item
        key="1"
        onClick={() => navigate(`/registrar-usuario/${client?.cpf}`)}
      >
        <span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <EditOutlined />
          Editar
        </span>
      </MenuAntd.Item>
    </MenuAntd>
  );

  const MenuOptions = [
    {
      label: 'Início',
      icon: <HomeOutlined />,
      path: '/home',
    },
    {
      label: 'Sobre',
      icon: <InfoCircleOutlined />,
      path: '/sobre',
    },
    {
      label: 'Dúvidas Frequentes',
      icon: <QuestionOutlined />,
      path: '/duvidas-frequentes',
    },
  ];

  return (
    <>
      {isLogged && toggleMenu && (
        <MenuMobile>
          <div className="close-btn" onClick={() => setToggleMenu(!toggleMenu)}>
            <CloseOutlined />
          </div>
          <div className="content-user">
            <span>{nome}</span>
            <LogoutOutlined onClick={() => signOut()} />
          </div>
          <Divider />
          <ul>
            {MenuOptions.map(option => (
              <li>
                <Link onClick={() => setToggleMenu(false)} to={option.path}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'flex-start',
                    }}
                  >
                    {option.icon}
                    {option.label}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </MenuMobile>
      )}
      <Container>
        <header className="main">
          <div className="contain">
            <div className={isLogged ? 'greenBar' : 'greenBarNotLogged'}>
              {isLogged ? (
                <div className="content-logos">
                  <div className="content-logo" onClick={redirectToHome}>
                    MEU CELULAR
                  </div>
                  <div className="content-sspds-logo">
                    <img
                      className="logoImg"
                      src={logoBrasao}
                      alt="SSPDSclara2"
                      style={{ width: '50px', height: '50px' }}
                      onClick={redirectToHome}
                    />
                    <div
                      className="logoNameSecretaria"
                      onClick={redirectToHome}
                    >
                      <b>SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL</b>
                      <p>GOVERNO DO ESTADO DO CEARÁ</p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="content-logos">
                    <div
                      className="content-logo-not-logged"
                      onClick={redirectToHome}
                    >
                      MEU CELULAR
                    </div>
                    <div className="content-sspds-logo">
                      <img
                        className="logoImg"
                        src={logoBrasao}
                        alt="SSPDSclara2"
                        style={{ width: '45px', height: '50px' }}
                        onClick={redirectToHome}
                      />
                      <div
                        className="logoNameSecretaria"
                        onClick={redirectToHome}
                      >
                        <b>SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL</b>
                        <p>GOVERNO DO ESTADO DO CEARÁ</p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {isLogged && (
                <div className="profile">
                  <aside>
                    <Profile>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span id="userName">{nome}</span>
                      </div>

                      <Dropdown overlay={menu}>
                        <Avatar
                          style={{
                            backgroundColor: '#f56a00',
                            border: '2px solid #fff',
                            verticalAlign: 'middle',
                          }}
                          src={image}
                          size="large"
                        >
                          {user}
                        </Avatar>
                      </Dropdown>
                    </Profile>
                  </aside>
                </div>
              )}
            </div>

            <div className="header_content_menu">
              {isLogged && (
                <>
                  <div className="contentMenu">
                    <Link to="/home">
                      {' '}
                      <p>Início</p>{' '}
                    </Link>
                    <Link to="/sobre">
                      {' '}
                      <p
                        style={{
                          borderLeft: '2px solid rgb(74 222 128)',
                          borderRight: '2px solid rgb(74 222 128)',
                          paddingLeft: '3rem',
                          paddingRight: '3rem',
                        }}
                      >
                        Sobre
                      </p>{' '}
                    </Link>
                    <Link to="/duvidas-frequentes">
                      <p>Dúvidas Frequentes</p>{' '}
                    </Link>
                  </div>
                  <div
                    className="content-menu-icon"
                    onClick={() => setToggleMenu(!toggleMenu)}
                  >
                    <MenuOutlined />
                  </div>
                </>
              )}
            </div>
            <div className="content-logo-bottom">
              <img
                width={300}
                height={100}
                style={{ width: '100%' }}
                src={BaseOndas}
                alt=""
              />
            </div>
          </div>
        </header>
      </Container>
    </>
  );
}

/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
} from 'react';
import { toast } from 'react-toastify';
import { apiAuth, AuthService } from '../services/auth';
import { AuthProps, SiaaData } from 'interfaces/auth';
import decodeJwt from 'utils/decodeJwt';

// @ts-ignore
const AuthContext = createContext<AuthProps>();

// eslint-disable-next-line react/prop-types
function AuthProvider({ children }: { children: ReactNode }) {
  const { login } = AuthService();
  const [data, setData] = useState(() => {
    const token = sessionStorage.getItem('@SSPDS_ALERTA:token');
    const client = sessionStorage.getItem('@SSPDS_ALERTA:client');
    if (token && client) {
      // api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, client: JSON.parse(client) };
    }
    return {};
  });

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@SSPDS_ALERTA:token');
    sessionStorage.removeItem('@SSPDS_ALERTA:client');
    window.location.assign("/login");
    setData({});
  }, []);

  const signIn = useCallback(
    async ({ cpf, password }: { cpf: string; password: string }) => {
      try {
        const { token, client, firstAccess } = await login(cpf, password);

        sessionStorage.setItem('@SSPDS_ALERTA:token', token || '');
        sessionStorage.setItem(
          '@SSPDS_ALERTA:client',
          JSON.stringify({
            name: client?.name,
            cpf: client?.cpf,
            cellphone: client?.cellphone,
          })
        );
        sessionStorage.setItem('firstAccess', firstAccess);

        const tokenDecoded = decodeJwt(token);
        const isOnly190User = 
        tokenDecoded.role?.includes('ROLE_190_OCORRENCIA_PANICO') && 
        !tokenDecoded.role?.includes('ROLE_ALERTA_CELULAR_CONTA_DEFAULT');

        if(isOnly190User) {
          return 'isOnly190User'
        }

        if (token && !isOnly190User) {
          setData({
            token,
            client,
          });
          return 'isAuthenticated';
        } else {
          return 'isNotAuthenticated';
        }

        // const teste = await api.get('/util/online');
        // if (teste.status === 200) {
        //   setData({ token: token || '', client });
        // } else {
        //   throw new Error('servico indisponível');
        // }
      } catch (err: any) {
        console.error(err);
        // if (
        //   err.message == 'Network Error' ||
        //   err.message == 'Request failed with status code 404'
        // ) {
        //   sessionStorage.removeItem('@SSPDS_ALERTA:token');
        //   sessionStorage.removeItem('@SSPDS_ALERTA:client');
        //   toast.error('Serviço temporariamente indisponivel.');
        // } else {
        //   err.message !== 'servico indisponivel'
        //     ? toast.error('Verifique suas credenciais')
        //     : toast.error('Serviço temporariamente indisponivel.');
        // }
      }
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        client: data?.client || { nome: '' },
        /* roles: data.roles, */
        token: data?.token,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

let authTokenRequest: any;

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = apiAuth.post('/token');
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

export { AuthProvider, useAuth, getAuthToken };

import React, { useState, useCallback } from 'react';
import ReactInputMask from 'react-input-mask';
import {
  SendOutlined,
  CheckCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';

import { Form, Button, Checkbox, Input, Spin } from 'antd';
import { toast } from 'react-toastify';

import { useNavigate, useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import devPrint from '../../utils/devPrint';
// eslint-disable-next-line no-unused-vars
import CodeVerification from '../../services/code.verification';
// import { FiAlignJustify } from "react-icons/fi";
// import { Simulate } from "react-dom/test-utils";
// import { border } from "polished";

const red = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
// eslint-disable-next-line no-unused-vars
const strongRegex = new RegExp(red);

export default function RecuperaSenha() {
  const [form] = Form.useForm();
  const [cpf, setCpf] = useState('');
  const [possuiCodigo, setPossuiCodigo] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const label = 'Já recebi meu código de Recuperação';

  const finish = useCallback(async values => {
    setLoading(true);

    if (values.password !== values.passwordConfirmation) {
      toast.error('As senhas não coincidem');
      setLoading(false);
      return;
    }

    try {
      // devPrint("Tentando");
      const patt1 = /[0-9]/g;
      // devPrint("Variavel CPF:"+ cpf);
      // const new_cpf = cpf.match(patt1).join("");
      // eslint-disable-next-line no-param-reassign
      values.cpf = values.cpf.match(patt1).join('');

      const { data } = await CodeVerification.createPassword(values);
      setLoading(false);
      toast.success('Senha Alterada');
      navigate('/login');
    } catch (e) {
      toast.error(e);
    } finally {
      devPrint('Final');
      setLoading(false);
    }
  }, []);

  function alteraLabel() {
    setPossuiCodigo(!possuiCodigo);
  }

  async function sendConde() {
    if (cpf.length !== 14) {
      devPrint(`Location${JSON.stringify(location.pathname)}`);
      toast.error('CPF inválido');
      return;
    }
    const patt1 = /[0-9]/g;
    const cpfSend = cpf.match(patt1).join('');
    setLoading(true);
    try {
      const { data } = await CodeVerification.sendCodeVerification(cpfSend);
      toast.success(JSON.stringify(data.messageForUser));
      devPrint(data.messageForUser);
      alteraLabel();
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }

  const atualizaCPF = e => {
    // const patt1 = /[0-9]/g;
    // cpf = cpf.match(patt1).join("");
    // devPrint("teste");
    const valor = e.target.value;
    // devPrint("Changing: "+ e.target.value);
    setCpf(valor);
    // devPrint("CPF: " + cpf);
  };

  return (
    <Spin spinning={loading} className={styles.spin}>
      <Form layout="vertical" onFinish={finish} form={form} name="basic">
        <div className={styles.container}>
          <div className={styles.cardVerification}>
            <div className={styles.contentTitle}>
              <LeftOutlined onClick={() => navigate('/login')} />
              <h1> Recuperação de senha</h1>
            </div>
            <p>
              Passo 1 : Utilize seu CPF para enviar um código de recuperação
              para o email cadastro.
            </p>
            <p>
              Passo 2 : Utilize o código recebido no email para registrar uma
              nova senha.
            </p>

            <Form.Item
              label="CPF"
              // className={styles.inputFirstForm}
              value={cpf}
              defaultValue={cpf}
              name="cpf"
            >
              <ReactInputMask
                className={styles.input}
                mask="999.999.999-99"
                required
                // value = {cpf}
                name="cpf"
                onChange={e => atualizaCPF(e)}
                value={cpf}
                defaultValue={cpf}
                type="text"
                placeholder="CPF"

                /* onBlur={verificaVazio} */
              />
            </Form.Item>
            {!possuiCodigo ? (
              <Button
                className={styles.btnSend}
                label="Gerar Código"
                onClick={() => sendConde()}
              >
                Gerar Código <SendOutlined />
              </Button>
            ) : (
              <div />
            )}
            <Checkbox
              className={styles.labelCheck}
              checked={possuiCodigo}
              value={possuiCodigo}
              // disabled={possuiCodigo}
              onChange={() => alteraLabel()}
            >
              {label}
            </Checkbox>

            {possuiCodigo ? (
              <div>
                <Form.Item
                  label="Código"
                  name="code"
                  rules={[{ required: true, message: 'Obrigatório' }]}
                >
                  <Input
                    className={styles.input}
                    required
                    type="text"
                    placeholder="Digite o código"
                    /* onBlur={verificaVazio} */
                  />
                </Form.Item>
                <Form.Item
                  label="Nova Senha"
                  name="password"
                  rules={[
                    { required: true, message: 'Obrigatório' },
                    { min: 8 },
                  ]}
                >
                  <Input.Password
                    className={styles.inputpassword}
                    required
                    type="text"
                    placeholder="Senha"
                    value={cpf}
                  />
                </Form.Item>
                <Form.Item
                  label="Confirmar Nova Senha"
                  name="passwordConfirmation"
                  rules={[{ required: true, message: 'Obrigatório' }]}
                >
                  <Input.Password
                    className={styles.inputpassword}
                    required
                    type="text"
                    placeholder="Confirmar senha"
                  />
                </Form.Item>
                <div className={styles.areaButton}>
                  <Button className={styles.btnConfirmar} htmlType="submit">
                    <CheckCircleOutlined /> Criar nova senha
                  </Button>
                </div>
              </div>
            ) : (
              <div> </div>
            )}
          </div>
        </div>
      </Form>
    </Spin>
  );
}

// opcoes: localhost, dev, prod, stage

const getVariablesByEnv = () => {
  switch (process.env.REACT_APP_AMB) {
    case 'local':
      return {
        server: 'http://172.25.100.132:30070/auditoria',
        authServer: 'https://spca-dev.sspds.ce.gov.br/api/app/auth'
      };
    case 'dev': {
      return {
        server: 'https://spca-dev.sspds.ce.gov.br/api/app/alerta',
        authServer: 'https://spca-dev.sspds.ce.gov.br/api/app/auth'
      };
    };
    case 'prod': {
      return {
        server: 'https://spca.sspds.ce.gov.br/api/app/alerta',
        authServer: 'https://spca.sspds.ce.gov.br/api/app/auth'
      }
    }
    default:
      return {
        server: 'https://spca-dev.sspds.ce.gov.br/api/app/alerta',
        authServer: 'https://spca-dev.sspds.ce.gov.br/api/app/auth'
      };
  }
};

export default {
	...getVariablesByEnv(),
};

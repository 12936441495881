import axios from 'axios';
import { getAuthToken } from 'hooks/auth';
import { toast } from 'react-toastify';
import ENV from '../url_config'
const { server } = ENV;

const api = axios.create({ 
  baseURL: server,
  timeout: 1000 * 5,
  retry: 1
});
 
api.interceptors.request.use(async (config) => {
  const token = sessionStorage.getItem('@SSPDS_ALERTA:token');
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

api.interceptors.request.use(
  res => res,
  error => Promise.reject(error)
);

let isUnauthorizedError = false;

const authorizationError = () => {
    if(!isUnauthorizedError) {
      toast.error('Erro de autorização, por favor logue novamente');
      isUnauthorizedError = true;
    }
    return setTimeout(() => {
      sessionStorage.removeItem('@SSPDS_ALERTA:token');
      sessionStorage.removeItem('@SSPDS_ALERTA:client');
      window.location.assign("/login");
    }, 2 * 1000);
}

const handleError = async (config, response, message ) => {
  try {
    if (message.includes('timeout') || message.includes('500'))
      {
        if (config.retry < 5) {
          config.retry += 1;
    
          const backoff = new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 2 * 1000);
          });
    
          backoff.then(() => {
            api(config);
          });
        } else {
          let errorResponse = {
            message: 'Desculpe, ocorreu um erro ao processar a sua solicitação.',
            status: response.status
          }
          return Promise.reject(errorResponse);
        }
    }
    if (message.includes('401') || message.includes('403')) {
      authorizationError();
    }
  } catch(err) {
    console.error(err);
  }
};

api.interceptors.response.use(
  (response) => response, 
  (error) => {
    let errorResponse = {};
    handleError(error.config, error.response, error.message, isUnauthorizedError);
    if(error?.response?.data?.userMessage) {
      errorResponse = {
        message: error.response.data.userMessage,
        status: error.response?.status
      }
    } else {
      errorResponse = {
        message: 'Desculpe, ocorreu um erro ao processar a sua solicitação.',
        status: error?.response?.status
      }
    }
    if(!isUnauthorizedError) {
      return Promise.reject(errorResponse);
    } else {
      return Promise.reject();
    }
});

export default api;

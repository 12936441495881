import { useState, useCallback, useEffect } from 'react';
import { Form, Button, Spin, Input, Select, Modal } from 'antd';
import {
  CheckCircleOutlined,
  LeftOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.css';
import ImgSSPDS from '../../assets/AlertaCelularLogo.svg';
import api from '../../services/api';
import { AuthService } from '../../services/auth';
import listUF from 'utils/getUF';
import UserService from 'services/user';
import { useAuth } from 'hooks/auth';
import decodeJwt from 'utils/decodeJwt';
import moment from 'moment';
import { secretsQuestions } from 'utils/constants';
import { useEncription } from 'utils/encription';

export default function RegisterUser() {
  const cursoId = 'turnoCurso1';
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const today = moment();
  const minAge = 18; // Idade mínima permitida
  const maxDate = today.subtract(minAge, 'years'); // Data máxima permitida
  let dateMask;
  const day = maxDate.date();
  const month = maxDate.month();
  const year = maxDate.year();

  const maxDateFormated = `${year}-${month}-${day}`; // Data máxima permitida formatada

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const { register, update, getuserByCpf } = AuthService();

  const { id } = useParams();
  const isEdit = !!id;

  const [acceptTerms, setAcceptTerms] = useState(Boolean(id));
  const { token } = useAuth();

  const initFetch = useCallback(async () => {
    try {
      setLoading(true);
      const userDetails = await UserService.getUserById();

      form.setFieldsValue({
        birth: userDetails?.data?.birth,
        cellphone: formatCellphone(userDetails?.data?.cellphone),
        cellphone1: formatCellphone(userDetails?.data?.cellphone1),
        cellphone2: formatCellphone(userDetails?.data?.cellphone2),
        cep: formatCEP(userDetails?.data?.address?.cep),
        rua: userDetails?.data?.address?.rua,
        number: userDetails?.data?.address?.number,
        ufAdress: userDetails?.data?.address?.uf,
        municipio: userDetails?.data?.address?.municipio,
        bairro: userDetails?.data?.address?.bairro,
        complement: userDetails?.data?.address?.complement,
        cpf: formatCPF(userDetails?.data?.cpf),
        email: userDetails?.data?.email,
        issuing: userDetails?.data?.issuing,
        rg: userDetails?.data?.rg,
        uf: userDetails?.data?.uf,
        ufAdress: userDetails?.data?.address?.uf,
        username: userDetails?.data.username,
      });
    } catch (err) {
      toast.error('Erro ao buscar informações do usuário');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      initFetch();
    } else if (location?.state?.cpf) {
      form.setFieldsValue({ cpf: location.state.cpf });
    }
  }, [id]);

  const allUf = listUF.map(uf => {
    return {
      value: uf.sigla,
      label: uf.sigla,
    };
  });

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const validateCEP = (rule, value, callback) => {
    const cepRegex = /^\d{5}-\d{3}$/;

    if (!cepRegex.test(value)) {
      callback('Por favor, insira um CEP válido com 8 dígitos.');
    } else {
      callback();
    }
  };

  const validateCPF = (rule, value, callback) => {
    const cleanedValue = value.replace(/\D/g, ''); // Remove não-dígitos
    const cpfRegex = /^[0-9]{11}$/; // Expressão regular para validar CPF (11 dígitos numéricos)

    if (!cpfRegex.test(cleanedValue)) {
      callback('Por favor, insira um CPF válido com 11 dígitos.');
    } else {
      // Verificação do dígito verificador
      const cpf = value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(cpf.substring(9, 10))) {
        callback('Por favor, insira um CPF válido.');
      }

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(cpf.substring(10, 11))) {
        callback('Por favor, insira um CPF válido.');
      }

      callback();
    }
  };

  const formatCEP = value => {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
    return value;
  };

  const formatCPF = value => {
    if (value)
      return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    else return '';
  };

  const handleCPFChange = e => {
    const { value } = e.target;
    const formattedValue = formatCPF(value);
    form.setFieldsValue({
      cpf: formattedValue,
    });
  };

  const formatCellphone = value => {
    if (value) {
      if (value.length <= 13) {
        return value
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '($1)$2')
          .replace(/(\d{4})(\d)/, '$1-$2');
      } else {
        return value
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '($1)$2')
          .replace(/(\d{5})(\d)/, '$1-$2');
      }
    } else return '';
  };

  const formatDate = value => {
    // Verifica se a string está no formato esperado
    const regexData = /^\d{4}-\d{2}-\d{2}$/;
    if (!regexData.test(value)) {
      return 'Por favor, forneça uma data válida.';
    }

    // Extrai o dia, mês e ano da string
    const partesData = value.split('-');
    const ano = partesData[0];
    const mes = partesData[1];
    const dia = partesData[2];

    // Retorna a data formatada no formato brasileiro
    return `${dia}/${mes}/${ano}`;
  };

  const handleCellphoneChange = (e, field) => {
    const { value } = e.target;
    const formattedValue = formatCellphone(value);

    if (field === 'emergency1') {
      form.setFieldsValue({
        cellphone1: formattedValue,
      });
    } else if (field === 'emergency2') {
      form.setFieldsValue({
        cellphone2: formattedValue,
      });
    } else if (field === 'cellphone') {
      form.setFieldsValue({
        cellphone: formattedValue,
      });
    }
  };

  const handleCEPChange = e => {
    const { value } = e.target;
    const formattedValue = formatCEP(value);
    form.setFieldsValue({
      cep: formattedValue,
    });
  };

  const validateMax13Chars = value => {
    if (value) {
      form.setFieldsValue({
        rg: value.substring(0, 13),
      });
    }
  };

  async function onBlurCep(ev) {
    try {
      const { value } = ev.target;
      const cep = value?.replace(/[^0-9]/g, '');

      if (cep?.length !== 8) {
        return;
      }

      const response = await api.get(
        `https://spca.sspds.ce.gov.br/prod/api/ficha/cep?cep=${cep}`
      );

      if (response.data) {
        // setShowAddressFields(true);

        const { municipio, rua, bairro, uf } = response.data;

        form.setFieldsValue({
          rua: rua || '',
          bairro,
          municipio,
          ufAdress: uf,
        });
      } else {
        toast.warning('CEP não encontrado');
      }
    } catch (e) {
      // setShowAddressFields(true);
      toast.error('Serviço de buscar CEP indisponível');
    }
  }

  const onFinish = useCallback(async values => {
    try {
      if (
        moment(values.birth).isSameOrAfter(
          moment().set('year', moment().year() - 18)
        )
      ) {
        toast.info('O usuário deve ter pelo menos 18 anos de idade');
        return;
      }

      const aniversario = values.birth.split('-');
      const birth = `${aniversario[2]}/${aniversario[1]}/${aniversario[0]}`;
      setLoading(true);

      if (id) {
        const userId = decodeJwt(token);

        const updateUser = {
          id: userId.sub,
          username: values.username,
          rg: values.rg,
          issuing: values.issuing,
          uf: values.uf,
          // cpf: values.cpf,
          cellphone: values.cellphone,
          cellphone1: values.cellphone1,
          cellphone2: values.cellphone2,
          // email: values.email,
          birth,
          address: {
            cep: values.cep,
            bairro: values.bairro,
            municipio: values.municipio,
            rua: values.rua,
            uf: values.ufAdress,
            number: values.number,
            complement: values.complement,
          },
          applicationId: 4,
        };

        updateUser.cpf = values.cpf.replaceAll('.', '').replaceAll('-', '');
        updateUser.address.cep = values.cep.replaceAll('-', '');

        const responseUpdate = await update(updateUser.cpf, updateUser);
        if (responseUpdate?.data == 1) {
          toast.success('Dados alterados');
          navigate('/home');
        } else {
          toast.warning('Erro ao atualizar dados');
          console.error(responseUpdate.data);
        }
      } else {
        const newUser = {
          username: values.username,
          rg: values.rg,
          issuing: values.issuing,
          uf: values.uf,
          cpf: values.cpf,
          cellphone: values.cellphone,
          cellphone1: values.cellphone1,
          cellphone2: values.cellphone2,
          birth,
          enabled: true,
          email: values.email,
          address: {
            cep: values.cep,
            bairro: values.bairro,
            municipio: values.municipio,
            rua: values.rua,
            uf: values.ufAdress,
            tipo: values.tipo,
            number: values.number,
            complement: values.complement,
          },
          applicationId: 4,
        };

        newUser.cpf = values.cpf.replaceAll('.', '').replaceAll('-', '');
        newUser.cep = values.cep.replaceAll('-', '');

        const data = await register(newUser);

        if (data === undefined) {
          throw new Error('Error na Requisição');
        }
        const cpfEncrypted = useEncription(values.cpf, 'encrypt');
        const cpfEncryptedFormated = cpfEncrypted.replaceAll('/', '');
        navigate(`/page-code/${cpfEncryptedFormated}`, {
          state: {
            cpf: cpfEncrypted,
            email: values?.email,
            question: values?.secretQuestion,
            answer: values?.answer,
            date: values?.birth,
          },
        });
      }
    } catch (e) {
      toast.error(e || 'Ocorreu um erro interno no sistema, tente novamente');
      setLoading(false);
    }
  }, []);

  const handleBackButton = () => {
    sessionStorage.removeItem('firstAccess');
    navigate('/home');
  };

  const handleDatePicker = e => {
    const value = e?.target?.value;
    // const spanErro = document.getElementById('spanErro');

    if (value && value.length == 10) {
      dateMask = value.substring(0, 10);
    }
    if (value && value.length > 10) {
      form.setFieldsValue({
        birth: dateMask,
      });
    }

    // if(dateMask.substring(0,4) > year) {
    //   spanErro.style.display = 'inline';
    // } else {
    //   spanErro.style.display = 'none';
    // }
  };

  const handleConfirmation = async () => {
    try {
      await form.validateFields();
      setIsModalOpen(true);
    } catch {
      toast.info('Por favor preencha todos os campos obrigatórios');
    }
  };

  const handlePaste = event => {
    event.preventDefault();
  };

  return (
    <Spin spinning={loading} className={styles.spin}>
      <div style={{ width: '100vw' }}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          className={styles.formulario}
          name="basic"
        >
          <div className={styles.camposFormulario}>
            <LeftOutlined
              id={styles.backButton}
              onClick={() => (id ? handleBackButton() : navigate('/login'))}
            />
            <div className={styles.logo}>
              <img src={ImgSSPDS} alt="SSPDS" />
              {/* <h3>Alerta Celular</h3> */}
            </div>
            <h3>{id ? 'Editar usuário' : 'Cadastro de usuário'}</h3>
            <div className={styles.linhaMaiorEsquerda}>
              <Form.Item
                className={styles.inputStyle}
                name="username"
                label="Nome completo"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  required
                  type="text"
                  placeholder="Nome completo"
                  /* onBlur={verificaVazio} */
                />
              </Form.Item>
            </div>

            <div div className={styles.linhaMaiorEsquerda}>
              <Form.Item
                className={styles.inputStyle}
                name="cpf"
                label="CPF"
                type="number"
                rules={[
                  {
                    required: true,
                    validator: validateCPF,
                  },
                ]}
              >
                <Input
                  placeholder="CPF"
                  className={styles.input}
                  onChange={handleCPFChange}
                  disabled={isEdit || location?.state?.cpf}
                  maxLength={14} // Limita o tamanho do campo
                />
              </Form.Item>

              <div className={styles.sizeBox} />
              <Form.Item
                className={styles.inputStyle}
                name="birth"
                label="Data de nascimento"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  required
                  name="dataNascimento"
                  placeholder="Data nascimento"
                  type="date"
                  onChange={handleDatePicker}
                  max={maxDateFormated}
                />
                {/* <span id="spanErro" style={{color: 'red', display:'none'}}>O usuário deve ter pelo menos 18 anos de idade.</span> */}
              </Form.Item>
            </div>

            <div div className={styles.linhaMaiorEsquerda}>
              <Form.Item
                className={styles.inputStyle}
                name="rg"
                label="RG/CIN"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  required
                  name="rg"
                  type="number"
                  placeholder="RG/CIN"
                  onChange={e => validateMax13Chars(e.target.value)}
                />
              </Form.Item>

              <div className={styles.sizeBox} />

              <Form.Item
                className={styles.inputStyle}
                name="issuing"
                label="Orgão"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  required
                  placeholder=""
                  maxLength={50}
                />
              </Form.Item>

              <div className={styles.sizeBox} />

              <Form.Item
                className={styles.inputStyle}
                name="uf"
                label="UF"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Select
                  virtual={false}
                  showSearch
                  placeholder="Selecione UF"
                  options={allUf}
                  filterOption={filterOption}
                />
              </Form.Item>
            </div>

            <div div className={styles.linhaMaiorEsquerda}>
              <Form.Item
                className={styles.inputStyle}
                name="email"
                label="E-mail"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                  { type: 'email', message: 'Inválido' },
                ]}
              >
                <Input
                  required
                  disabled={id}
                  className={styles.input}
                  name="email"
                  type="email"
                  placeholder="E-mail"

                  // onBlur={verificaVazio}
                />
              </Form.Item>
              {!isEdit && (
                <>
                  <div className={styles.sizeBox} />
                  <Form.Item
                    className={styles.inputStyle}
                    name="email-confirm"
                    label="Confirmar e-mail"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                      { type: 'email', message: 'Inválido' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('email') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Os emails não coincidem!')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      required
                      disabled={id}
                      className={styles.input}
                      name="email-confirm"
                      type="email"
                      placeholder="Confirmar e-mail"
                      onPaste={handlePaste}
                    />
                  </Form.Item>
                </>
              )}
            </div>

            <div className={styles.linhaMaiorEsquerda}>
              <Form.Item
                className={styles.inputStyle}
                name="cellphone"
                label="Telefone"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input
                  required
                  className={styles.input}
                  name="telefone"
                  type="tel"
                  placeholder="(00)00000-0000"
                  onChange={e => handleCellphoneChange(e, 'cellphone')}
                  maxLength={14}
                />
              </Form.Item>

              <div className={styles.sizeBox} />
              <Form.Item
                className={styles.inputStyle}
                name="cep"
                label="CEP"
                rules={[
                  {
                    required: true,
                    validator: validateCEP,
                  },
                ]}
              >
                {/* <Input
                  required
                  onBlur={(ev) => onBlurCep(ev)}
                  className={styles.input}
                  name="cep"
                  placeholder="CEP"
                /> */}
                <Input
                  placeholder="CEP"
                  required
                  onChange={handleCEPChange}
                  maxLength={9} // Limite o tamanho do campo
                  onBlur={ev => onBlurCep(ev)}
                  className={styles.input}
                />
              </Form.Item>
            </div>

            <>
              <div className={styles.linhaMaiorEsquerda}>
                <Form.Item
                  className={styles.inputStyle}
                  name="rua"
                  label="Logradouro"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Input required className={styles.input} placeholder="Rua" />
                </Form.Item>

                <div className={styles.sizeBox} />
                <div className={styles.linhaMaiorEsquerda}>
                  <Form.Item
                    className={styles.inputStyle}
                    name="number"
                    label="Número"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ]}
                  >
                    {/*      <p>Nº</p> */}
                    <Input
                      required
                      className={styles.input}
                      name="numero"
                      type="number"
                      placeholder="Número da residência"
                      maxLength={10}
                    />
                  </Form.Item>

                  <div className={styles.sizeBox} />
                  <Form.Item
                    className={styles.inputStyle}
                    name="complement"
                    label="Complemento"
                  >
                    <Input
                      className={styles.input}
                      name="complemento"
                      placeholder="Complemento"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className={styles.linhaMaiorEsquerda}>
                <Form.Item
                  className={styles.inputStyle}
                  name="bairro"
                  label="Bairro"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Input
                    required
                    className={styles.input}
                    name="bairro"
                    placeholder="Bairro"
                  />
                </Form.Item>
                <div className={styles.sizeBox} />
                <Form.Item
                  className={styles.inputStyle}
                  name="municipio"
                  label="Município"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Input
                    required
                    className={styles.input}
                    placeholder="Cidade"
                  />
                </Form.Item>
                <div className={styles.sizeBox} />
                <Form.Item
                  className={styles.inputStyle}
                  name="ufAdress"
                  label="UF"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Select
                    virtual={false}
                    showSearch
                    placeholder="Selecione UF"
                    options={allUf}
                    filterOption={filterOption}
                  />
                </Form.Item>
              </div>
            </>

            <div className={styles.linhaMaiorEsquerda}>
              <Form.Item
                className={styles.inputStyle}
                name="cellphone1"
                label="Número de contato 1"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input
                  required
                  className={styles.input}
                  placeholder="(00)00000-0000"
                  onChange={e => handleCellphoneChange(e, 'emergency1')}
                  maxLength={14}
                />
              </Form.Item>
              <div className={styles.sizeBox} />
              <Form.Item
                className={styles.inputStyle}
                name="cellphone2"
                label="Número de contato 2"
              >
                <Input
                  className={styles.input}
                  placeholder="(00)00000-0000"
                  onChange={e => handleCellphoneChange(e, 'emergency2')}
                  maxLength={14}
                />
              </Form.Item>
            </div>

            {!isEdit && (
              <div className={styles.linhaMaiorEsquerda}>
                <Form.Item
                  className={styles.inputStyle}
                  name="secretQuestion"
                  label="Pergunta secreta"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Select
                    virtual={false}
                    showSearch
                    placeholder="Selecione a pergunta secreta"
                    options={secretsQuestions}
                    filterOption={filterOption}
                  />
                </Form.Item>
                <div className={styles.sizeBox} />
                <Form.Item
                  className={styles.inputStyle}
                  name="answer"
                  label="Resposta"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Input required className={styles.input} />
                </Form.Item>
              </div>
            )}

            {!isEdit && (
              <div className={`${styles.itemUnico} ${styles.linhaCompleta}`}>
                <span
                  className={`${styles.slotGruposCheck} ${styles.turnoCurso}`}
                >
                  <p>
                    Concordo que meus dados sejam utilizados para consulta e
                    confirmação de características do meu aparelho para fins de
                    verificação de restrição de furto/extravío pelas forças
                    policiais.
                  </p>
                  <span className={`${styles.grupoCheck}`}>
                    <span>
                      <label htmlFor={cursoId}>
                        Concordo
                        <input
                          name="concordo"
                          style={{ marginLeft: '5px' }}
                          type="checkbox"
                          id={cursoId}
                          value="1"
                          onChange={() => {
                            setAcceptTerms(!acceptTerms);
                          }}
                        />
                      </label>
                      &nbsp;
                    </span>
                  </span>
                </span>
              </div>
            )}

            <div className={styles.buttonArea}>
              <Button
                className={
                  acceptTerms
                    ? styles.btnConfirmar
                    : styles.btnConfirmarDisabled
                }
                disabled={!acceptTerms}
                onClick={handleConfirmation}
              >
                <CheckCircleOutlined /> {id ? 'Atualizar' : 'Cadastrar'}
              </Button>

              <Button
                onClick={() => (id ? handleBackButton() : navigate('/login'))}
                className={styles.btnBack}
              >
                <RollbackOutlined /> Voltar
              </Button>
            </div>
          </div>
        </Form>
        <Modal
          title="Confirmar dados"
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
        >
          <Spin spinning={loading}>
            <div className={styles.confirmationFields}>
              <p style={{ width: '100%' }}>
                Nome: <span>{form?.getFieldValue('username') || ''}</span>
              </p>
              <p style={{ width: '100%' }}>
                Identidade:{' '}
                <span>
                  {form?.getFieldValue('rg') || ''}{' '}
                  {form?.getFieldValue('issuing') || ''}{' '}
                  {form?.getFieldValue('uf') || ''}
                </span>
              </p>
              <p>
                CPF: <span>{form?.getFieldValue('cpf') || ''}</span>
              </p>
              <p>
                Nascimento:{' '}
                <span>{formatDate(form?.getFieldValue('birth')) || ''}</span>
              </p>
              <p>
                Contato 1:{' '}
                <span>{form?.getFieldValue('cellphone1') || ''} </span>
              </p>
              <p>
                Contato 2:{' '}
                <span>{form?.getFieldValue('cellphone2') || ''} </span>
              </p>
              <p>
                Telefone: <span>{form?.getFieldValue('cellphone') || ''}</span>
              </p>
              <p style={{ width: '100%' }}>
                Endereço:{' '}
                <span>
                  {form?.getFieldValue('rua') || ''},{' '}
                  {form?.getFieldValue('number') || ''}{' '}
                  {form?.getFieldValue('bairro') || ''} -{' '}
                  {form?.getFieldValue('municipio') || ''}{' '}
                  {form?.getFieldValue('ufAdress') || ''}{' '}
                  {form?.getFieldValue('cep') || ''}
                </span>
              </p>
              <p style={{ width: '100%' }}>
                Email: <span>{form?.getFieldValue('email') || ''} </span>
              </p>
            </div>
            <div style={{ textAlign: 'end' }}>
              <Button
                className={styles.btnConfirmar}
                style={{ borderRadius: '30px' }}
                onClick={() => form?.submit()}
              >
                Salvar usuário
              </Button>
            </div>
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
}

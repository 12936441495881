import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from 'antd';
import { useCallback, useState } from 'react';
import {
  AddAlertModal,
  AddBoModal,
  Container,
  DeleteModal,
  RestitutionModal,
  SaveButton,
} from './style';
import imgExemplo from '../../../assets/exemplo.jpg';
import { InfoCircleOutlined } from '@ant-design/icons';
import cellphoneService from 'services/cellphone';
import { toast } from 'react-toastify';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { Art340Modal } from 'components/modals/art340';
import externalLinkImg from '../../../assets/link-externo.png';

interface ModalFunctionProps {
  showAlertModal: boolean;
  showBOModal: boolean;
  showRestitutionModal: boolean;
  showDeleteModal: boolean;
  cardId: string;
  setShowAlertModal: any;
  setShowBOModal: any;
  setShowRestitutionModal: any;
  setShowDeleteModal: any;
  // procedure: any;
  ocurrences: any;
  initFetch: any;
}

export default function Modals({
  showAlertModal,
  showBOModal,
  showRestitutionModal,
  showDeleteModal,
  cardId,
  setShowAlertModal,
  setShowBOModal,
  setShowRestitutionModal,
  setShowDeleteModal,
  // procedure,
  ocurrences,
  initFetch,
}: ModalFunctionProps) {
  const [alertForm] = Form.useForm();
  const [addBOForm] = Form.useForm();
  const [form] = Form.useForm();
  const [cancelPreAlertModal, setCancelPreAlertModal] = useState(false);
  const [loadingAlertModal, setLoadingAlertModal] = useState(false);
  const [loadingBoModal, setLoadingBoModal] = useState(false);
  // const [loadingRestitutionModal, setLoadingRestitutionModal] = useState(false);
  const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);
  const [hasProcedure, setHasProcedure] = useState(true);
  const [isLawModalOpen, setIsLawModalOpen] = useState(false);
  const YearPicker = DatePicker.YearPicker;

  const showLawModal = () => {
    setIsLawModalOpen(true);
  };

  const {
    addAlert,
    updateCellphoneToRecovered,
    updateCellphonePreAlert,
    deleteCellphone,
    rollbackCellphoneStatus,
  } = cellphoneService();

  // funçao para fechar modal
  const handleCancel = useCallback((modalName: string) => {
    switch (modalName) {
      case 'Alert':
        setShowAlertModal(false);
        break;
      case 'BO':
        setShowBOModal(false);
        break;
      case 'Restitution':
        setShowRestitutionModal(false);
        break;
      case 'Delete':
        setShowDeleteModal(false);
        break;
      case 'Law':
        setIsLawModalOpen(false);
        alertForm.resetFields();
        setShowAlertModal(false);
        addBOForm.resetFields();
        setShowBOModal(false);
        break;
      case 'PreAlert':
        setCancelPreAlertModal(false);
        break;
    }
    form.resetFields();
  }, []);

  //função para adicionar alerta ao dispositivo
  const onAddAlert = useCallback(
    async (values: any) => {
      try {
        setLoadingAlertModal(true); // seta o loading para true para carregar o spin
        // // Remove os espaços e separadores da máscara
        // const valorSemMascara = values.bo.replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '');

        // // Divide a string em partes
        // const delegacia = valorSemMascara.substring(0, 3);
        // const sequencial = valorSemMascara.substring(3,4);
        // const ano = valorSemMascara.substring(4,8);

        const { delegacia, sequencial, ano } = values;

        const params = {
          delegacia,
          sequencial,
          ano: moment(ano).format('YYYY'),
          tipoProcedimento: 'BOLETIM_DE_OCORRENCIA',
          dataOcorrencia:
            moment(values.dataOcorrencia).format('DD-MM-YYYY HH:mm:ss') || null,
          situacaoCelular: values.situation,
        };
        const addAlertResponse = await addAlert(cardId, params); // faz o request para adicionar o alerta
        if (
          addAlertResponse &&
          addAlertResponse?.message.includes('O celular está em alerta')
        ) {
          // caso não tenha erros no request mostrar mensagem de sucesso
          toast.success('Alerta adicionado');
          initFetch(false);
          addBOForm.resetFields();
          alertForm.resetFields();
          form.resetFields();
          setShowAlertModal(false);
          setShowBOModal(false);
          setIsLawModalOpen(false);
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        toast.warning('Erro ao adicionar alerta');
      } finally {
        setLoadingAlertModal(false); // seta o loading para false para parar de carregar o spin
      }
    },
    [cardId]
  );

  //função para adicionar um pré alerta
  const onAddPreAlert = useCallback(
    async (values: any) => {
      try {
        setLoadingAlertModal(true);
        const params = {
          dataOcorrencia: moment(values.date).format('DD-MM-YYYY HH:mm:ss'),
          situacaoCelular: values.situation,
        };

        const addPreAlertResponse = await updateCellphonePreAlert(
          cardId,
          params
        );
        if (
          addPreAlertResponse &&
          addPreAlertResponse?.data.message.includes(
            'O celular está em pré alerta'
          )
        ) {
          // caso não tenha erros no request mostrar mensagem de sucesso
          toast.warning('Celular em modo pré alerta');
          initFetch(false);
          addBOForm.resetFields();
          alertForm.resetFields();
          form.resetFields();
          setShowAlertModal(false);
        } else {
          // caso ocorra erros, mostrar os erros no console
          throw new Error(addPreAlertResponse?.msg);
        }
      } catch (err) {
        console.error(err);
        toast.warning('Erro ao adicionar pré alerta');
      } finally {
        setLoadingAlertModal(false);
      }
    },
    [cardId]
  );

  // função para restituir dispositivo
  // const onRefund = useCallback(
  //   async (values: any) => {
  //     try {
  //       setLoadingRestitutionModal(true); // seta o loading para true para carregar o spin
  //       // Remove os espaços e separadores da máscara
  //       const valorSemMascara = values.bo
  //         .replaceAll(' ', '')
  //         .replaceAll('-', '')
  //         .replaceAll('/', '');

  //       // Divide a string em partes
  //       const delegacia = valorSemMascara.substring(0, 3);
  //       const sequencial = valorSemMascara.substring(3, 4);
  //       const ano = valorSemMascara.substring(4, 8);
  //       const params = {
  //         delegacia,
  //         sequencial,
  //         ano,
  //         chaveObjeto: values.key,
  //         tipoProcedimento: values.procedureType,
  //         dataOcorrencia: values.date,
  //         situacaoCelular: values.situation,
  //       };
  //       const recoveryResponse = await updateCellphoneToRecovered(
  //         cardId,
  //         params
  //       ); // faz o request para restituir o dispositivo
  //       if (recoveryResponse) {
  //         // caso o request funcione mostrar mensagem de sucesso senão mostrar mensagem de erro
  //         toast.success('Alerta adicionado');
  //         initFetch(false);
  //       } else {
  //         throw new Error();
  //       }
  //     } catch (err) {
  //       toast.warning('Erro ao restituir dispositivo');
  //       console.error(err);
  //     } finally {
  //       setLoadingRestitutionModal(false); // seta o loading para false para parar de carregar o spin
  //     }
  //   },
  //   [cardId]
  // );

  //função para deletar dispositivo
  const onDelete = useCallback(
    async (values: any) => {
      try {
        setLoadingDeleteModal(true);
        const deleteResponse = await deleteCellphone(cardId);
        if (
          deleteResponse?.data &&
          deleteResponse?.data.message.includes('Celular deletado')
        ) {
          toast.success('Dispositivo deletado');
          initFetch(false);
          form.resetFields();
          setShowDeleteModal(false);
        } else {
          throw new Error(deleteResponse.msg);
        }
      } catch (err) {
        console.error(err);
        toast.warning('Erro ao deletar dispositivo');
      } finally {
        setLoadingDeleteModal(false);
      }
    },
    [cardId]
  );

  // função para cancelar pré alerta
  const onCancelPreAlert = useCallback(
    async (values: any) => {
      try {
        const cancelpreAlertResponse = await rollbackCellphoneStatus(
          cardId,
          values
        );
        if (
          cancelpreAlertResponse &&
          cancelpreAlertResponse?.data.message.includes('Ocorrencia cancelada')
        ) {
          toast.success('Pré alerta desfeito');
          initFetch(false);
          addBOForm.resetFields();
          alertForm.resetFields();
          form.resetFields();
          setCancelPreAlertModal(false);
          setShowBOModal(false);
        } else {
          throw new Error(cancelpreAlertResponse.msg);
        }
      } catch (err) {
        console.error(err);
        toast.warning('Erro ao cancelar pré alerta');
      }
    },
    [cardId]
  );

  const handleLawModalSubmit = useCallback(() => {
    if (showAlertModal) {
      return alertForm.submit();
    } else {
      return addBOForm.submit();
    }
  }, [showAlertModal]);

  // função para setar caso o dispositivo tenha procedimento ou não
  const handleSetHasProcedure = (e: string) => {
    form.resetFields();
    if (e === 'withProcedure') {
      setHasProcedure(true);
    } else {
      setHasProcedure(false);
    }
  };

  const validateCheckbox = (_: any, e: any) => {
    if (e) {
      return Promise.resolve();
    } else if (!e) {
      return Promise.reject('Campo obrigatório.');
    }
  };

  // const formatBOonPreAlert = (e: any) => {
  //     let value = e.target.value;

  //     // Remove caracteres não numéricos
  //     value = value.replace(/\D/g, '');

  //     // Aplica a máscara
  //     if (value.length > 3) {
  //         value = `${value.slice(0, 3)} - ${value.slice(3, 5)} / ${value.slice(5, 9)}`;
  //     }

  //     addBOForm.setFieldsValue({
  //         bo: value,
  //     });
  // };

  // const formatBOonAddAlert = (e: any) => {
  //     let value = e.target.value;

  //     // Remove caracteres não numéricos
  //     value = value.replace(/\D/g, '');

  //     // Aplica a máscara
  //     if (value.length > 3) {
  //         value = `${value.slice(0, 3)} - ${value.slice(3, 5)} / ${value.slice(5, 9)}`;
  //     }

  //     alertForm.setFieldsValue({
  //         bo: value,
  //     });
  // }

  const limitCharacters = (value: any, maxLength: number, modal: string) => {
    value = value.target.value;

    if (value) {
      if (modal === 'alertModal') {
        alertForm.setFieldsValue({
          delegacia: value.substring(0, maxLength),
        });
      } else {
        addBOForm.setFieldsValue({
          delegacia: value.substring(0, maxLength),
        });
      }
    }
  };

  const validateAndOpenModal = async (modal: string) => {
    try {
      if (modal === 'alertModal') {
        await alertForm.validateFields();
      } else {
        await addBOForm.validateFields();
      }
      showLawModal();
    } catch (errorInfo) {
      console.error('Falha na validação:', errorInfo);
    }
  };

  const disabledFutureDate = (current: any) => {
    return current && current > moment().endOf('day');
  };

  return (
    <Container>
      <AddAlertModal
        title="Adicionar Alerta"
        visible={showAlertModal}
        footer={null}
        onCancel={() => handleCancel('Alert')}
      >
        <Spin spinning={loadingAlertModal}>
          <div style={{ marginBottom: '10px' }}>
            <Radio.Group
              onChange={e => handleSetHasProcedure(e.target.value)}
              defaultValue="withProcedure"
            >
              <Radio value="withProcedure">Possuo registro na delegacia</Radio>
              <Radio value="withoutProcedure">
                Não possuo registro na delegacia
              </Radio>
            </Radio.Group>
          </div>

          {hasProcedure ? (
            <Form form={alertForm} onFinish={onAddAlert}>
              <div className="contentInstructions">
                <Image src={imgExemplo} />{' '}
              </div>
              <Form.Item
                name="situation"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder="Situação"
                  options={
                    ocurrences.length
                      ? ocurrences.map((elements: any) => {
                          return {
                            value: elements,
                            label: `${elements}`,
                          };
                        })
                      : [
                          {
                            value: '',
                            label: '',
                          },
                        ]
                  }
                />
              </Form.Item>

              {/* <Form.Item
                name="procedureType"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder="Procedimento"
                  options={
                    procedure.length
                      ? procedure.map((elements: any) => {
                          return {
                            value: elements,
                            label: `${elements
                              .replace('_', ' ')
                              .replace('_', ' ')}`,
                          };
                        })
                      : [
                          {
                            value: '',
                            label: '',
                          },
                        ]
                  }
                />
              </Form.Item> */}

              <Row gutter={24}>
                <Col span={24}>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Form.Item
                      name="delegacia"
                      rules={[
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ]}
                    >
                      {/* <Input placeholder="Número do procedimento" onChange={formatBOonAddAlert}/> */}
                      <Input
                        placeholder="Delegacia"
                        type="number"
                        onChange={value =>
                          limitCharacters(value, 3, 'alertModal')
                        }
                      />
                    </Form.Item>
                    <h2> - </h2>
                    <Form.Item
                      name="sequencial"
                      rules={[
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ]}
                    >
                      {/* <Input placeholder="Número do procedimento" onChange={formatBOonAddAlert}/> */}
                      <Input placeholder="Sequencial" type="number" />
                    </Form.Item>
                    <h2> / </h2>
                    <Form.Item
                      name="ano"
                      rules={[
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ]}
                    >
                      {/* <Input placeholder="Número do procedimento" onChange={formatBOonAddAlert}/> */}
                      <YearPicker disabledDate={disabledFutureDate} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Form.Item
                name="dataOcorrencia"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  style={{ width: '100%', height: '32px' }}
                  format="DD/MM/YYYY HH:mm:ss"
                  placeholder="Data da ocorrência"
                />
              </Form.Item>

              <Form.Item
                name="check"
                valuePropName="checked"
                rules={[
                  {
                    validator: validateCheckbox,
                  },
                ]}
                initialValue={true}
              >
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Checkbox defaultChecked={true} />
                  <p style={{ marginLeft: '5px' }}>
                    Eu confirmo que as informações acima são verídicas e estou
                    ciente de que falsas informações se enquadram como crime.
                    {/* <InfoCircleOutlined style={{color:'orange', marginLeft:'5px'}} onClick={showLawModal}/> */}
                  </p>
                </div>
              </Form.Item>

              <div className="contentButton">
                <SaveButton
                  color="green"
                  onClick={() => validateAndOpenModal('alertModal')}
                >
                  Confirmar
                </SaveButton>
              </div>
            </Form>
          ) : (
            <Form form={form} onFinish={onAddPreAlert}>
              <p
                style={{
                  background: '#b22222',
                  color: '#fff',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                <b>IMPORTANTE</b>: Você deverá preencher o restante das
                informações necessárias em um prazo de até 72 horas para que o
                alerta persista no sistema.
                <br />
                <a
                  href="https://www.delegaciaeletronica.ce.gov.br/beo"
                  target="_blank"
                >
                  <div className="content-bo-info">
                    Para registar um boletim eletrônico de ocorrência, clique
                    aqui
                    <img src={externalLinkImg} alt="sobre.png" />
                  </div>
                </a>
              </p>
              <Form.Item
                name="situation"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder="Situação"
                  options={
                    ocurrences.length
                      ? ocurrences.map((elements: any) => {
                          return {
                            value: elements,
                            label: `${elements}`,
                          };
                        })
                      : [
                          {
                            value: '',
                            label: '',
                          },
                        ]
                  }
                />
              </Form.Item>

              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <DatePicker
                  placeholder="Data da ocorrência"
                  showTime
                  format="DD/MM/YYYY HH:mm:ss"
                  disabledDate={disabledFutureDate}
                />
              </Form.Item>
              <div className="contentButton">
                <SaveButton color="green" htmlType="submit">
                  Confirmar
                </SaveButton>
              </div>
            </Form>
          )}
        </Spin>
      </AddAlertModal>

      <AddBoModal
        title="Vincular procedimento policial"
        visible={showBOModal}
        footer={null}
        onCancel={() => handleCancel('BO')}
      >
        <Spin spinning={loadingBoModal}>
          <Form form={addBOForm} onFinish={onAddAlert}>
            <div className="contentInstructions">
              <Image src={imgExemplo} />{' '}
            </div>
            <Form.Item
              name="situation"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Select
                placeholder="Situação"
                options={
                  ocurrences.length
                    ? ocurrences.map((elements: any) => {
                        return {
                          value: elements,
                          label: `${elements}`,
                        };
                      })
                    : [
                        {
                          value: '',
                          label: '',
                        },
                      ]
                }
              />
            </Form.Item>

            {/* <Form.Item
              name="procedureType"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ]}
            >
              <Select
                placeholder="Procedimento"
                options={
                  procedure.length
                    ? procedure.map((elements: any) => {
                        return {
                          value: elements,
                          label: `${elements
                            .replace('_', ' ')
                            .replace('_', ' ')}`,
                        };
                      })
                    : [
                        {
                          value: '',
                          label: '',
                        },
                      ]
                }
              />
            </Form.Item> */}

            <Row gutter={24}>
              <Col span={24}>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <Form.Item
                    name="delegacia"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ]}
                  >
                    {/* <Input placeholder="Número do procedimento" onChange={formatBOonAddAlert}/> */}
                    <Input
                      placeholder="delegacia"
                      type="number"
                      onChange={value =>
                        limitCharacters(value, 3, 'addBOModal')
                      }
                    />
                  </Form.Item>
                  <h2> - </h2>
                  <Form.Item
                    name="sequencial"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ]}
                  >
                    {/* <Input placeholder="Número do procedimento" onChange={formatBOonAddAlert}/> */}
                    <Input placeholder="sequencial" type="number" />
                  </Form.Item>
                  <h2> / </h2>
                  <Form.Item
                    name="ano"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ]}
                  >
                    {/* <Input placeholder="Número do procedimento" onChange={formatBOonAddAlert}/> */}
                    <YearPicker disabledDate={disabledFutureDate} />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Form.Item
              name="dataOcorrencia"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm:ss' }}
                style={{ width: '100%', height: '32px' }}
                format="DD/MM/YYYY HH:mm:ss"
                placeholder="Data da ocorrência"
              />
            </Form.Item>

            <Form.Item
              name="check"
              valuePropName="checked"
              rules={[
                {
                  validator: validateCheckbox,
                },
              ]}
              initialValue={true}
            >
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Checkbox defaultChecked={true} />
                <p style={{ marginLeft: '5px' }}>
                  Eu confirmo que as informações acima são verídicas e estou
                  ciente que falsas informações se enquadra como crime
                  {/* <InfoCircleOutlined style={{color:'orange', marginLeft:'5px'}} onClick={showLawModal}/> */}
                </p>
              </div>
            </Form.Item>
            <div className="contentButton">
              <SaveButton
                color="green"
                onClick={() => validateAndOpenModal('addBOModal')}
              >
                Confirmar
              </SaveButton>
              <SaveButton
                color="rgb(255, 69, 0)"
                onClick={() => setCancelPreAlertModal(true)}
              >
                Cancelar pré-alerta
              </SaveButton>
            </div>
          </Form>
        </Spin>
      </AddBoModal>

      {/* <RestitutionModal
        title="Restituir"
        visible={showRestitutionModal}
        footer={null}
        onCancel={() => handleCancel('Restitution')}
      >
        <Spin spinning={loadingRestitutionModal}>
          <Form form={form} onFinish={onRefund}>
            <div className="contentInstructions">
              <Image src={imgExemplo} />{' '}
            </div>
            <Form.Item
              name="procedureType"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ]}
            >
              <Select
                placeholder="Procedimento"
                options={
                  procedure.length
                    ? procedure.map((elements: any) => {
                        return {
                          value: elements,
                          label: `${elements
                            .replace('_', ' ')
                            .replace('_', ' ')}`,
                        };
                      })
                    : [
                        {
                          value: '',
                          label: '',
                        },
                      ]
                }
              />
            </Form.Item>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="bo"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Número do procedimento" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="key"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input placeholder="Número do alerta celular" />
            </Form.Item>
            <Form.Item
              name="check"
              valuePropName="checked"
              rules={[
                {
                  validator: validateCheckbox,
                },
              ]}
              initialValue={true}
            >
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Checkbox defaultChecked={true} />
                <p style={{ marginLeft: '5px' }}>
                  Eu confirmo que as informações acima são verídicas e estou
                  ciente que falsas informações se enquadra como crime
                  <InfoCircleOutlined
                    style={{ color: 'orange', marginLeft: '5px' }}
                    onClick={showLawModal}
                  />
                </p>
              </div>
            </Form.Item>
            <div className="contentButton">
              <SaveButton color="green" htmlType="submit">
                Restituir
              </SaveButton>
            </div>
          </Form>
        </Spin>
      </RestitutionModal> */}

      <DeleteModal
        title="Deletar"
        visible={showDeleteModal}
        footer={null}
        onCancel={() => handleCancel('Delete')}
      >
        <Spin spinning={loadingDeleteModal}>
          <h3 style={{ marginBottom: '25px' }}>
            Você deseja realmente excluir esse dispositivo?
          </h3>
          <div className="contentButton">
            <SaveButton color="green" onClick={onDelete}>
              Confirmar
            </SaveButton>
            <SaveButton color="red" onClick={() => handleCancel('Delete')}>
              Cancelar
            </SaveButton>
          </div>
        </Spin>
      </DeleteModal>

      <Modal
        title="Cancelar pré alerta"
        visible={cancelPreAlertModal}
        footer={null}
        onCancel={() => handleCancel('PreAlert')}
      >
        <Form onFinish={onCancelPreAlert}>
          <Form.Item
            name="justificativa"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
          >
            <TextArea placeholder="Justificar Cancelamento" maxLength={400} />
          </Form.Item>
          <SaveButton color="green" htmlType="submit">
            Confirmar
          </SaveButton>
        </Form>
      </Modal>

      <Art340Modal
        isVisible={isLawModalOpen}
        setIsVisible={setIsLawModalOpen}
        handleLawModalSubmit={handleLawModalSubmit}
      />
    </Container>
  );
}

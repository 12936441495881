import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { Input, Spin } from 'antd';

export const Container = styled.div`
  font-family: sans-serif !important;
  display: flex;
  background-image: url('../../assets/BG_MeuCelular.png');
  background-size: cover;
  min-height: 92vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media screen and (max-width: 600px) {
    min-height: 120vh;
  }
  /* align-items: stretch; */
  .buttonCancelar {
    background: #f1f1f1;
    color: #424242;
    border: 1px solid #cecece !important;
    margin-top: 0px;
    height: 37px;
  }

  .buttonRecSenha {
    background: none;
    color: #424242;
    border: none;
    margin-top: 0px;
  }
`;

export const ModalValidateUser = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content-body {
    /* background: #fff; */
    /* height: 70%;
        width: 100%;
        padding: 30px 50px;
        border-radius: 5px; */
    /* border: 1px solid #cecece; */
  }

  .content-body h1 {
    font-weight: 600;
    color: #707070;
    text-align: center;
    margin-bottom: 30px;
  }

  .content-body p {
    font-weight: 400;
    color: #707070;
  }

  .content-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 10px;
  }

  .content-title span {
    background-color: transparent;
    color: #00853b;
    font-size: 20px;
    cursor: pointer;
    /* @media screen and (min-width:767px) {
        position: absolute;
        top: 73px;
        left: 70px;
        padding-left: 5px;
        } */
  }

  .content-title span:hover {
    color: #00853b;
    transform: scale(1.2);
    transition: all 0.4s ease-out;
    box-shadow: none !important;
  }

  .btnConfirmar {
    border: none !important;
    background: #00853b !important;
    color: white !important;
    margin-right: 10px !important;
    height: 40px !important;
    border-radius: 50px !important;
    width: 100%;
  }

  .content-buttons {
    text-align: center;
    width: 100%;
  }
`;

export const StepByStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-top: 2rem;
  gap: 3.5rem;
  padding: 2rem;
  font-weight: bold;
  min-height: 72vh;
  width: 33%;

  @media screen and (max-width: 1536px) {
    width: 50%;
  }

  .content-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .content-logo img {
    width: 80%;
  }

  .text-header {
    font-size: calc(1.5rem + 0.4vw);
    width: 80%;
    line-height: 1.3;

    @media screen and (max-width: 2400px) {
      font-size: 1.5rem;
    }
  }
  .text-header span {
    background: #e06a0c;
    border-radius: 0.5rem;
    padding-left: 0.2rem;
    padding-right: 0.3rem;
  }

  .content-steps {
    width: 80%;
    padding-top: 1rem;
    font-size: calc(1rem + 0.4vw);

    @media screen and (max-width: 2400px) {
      font-size: 1rem;
    }
  }
  .content-steps ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    list-style: none;
  }
  .content-steps ul li span {
    padding-left: 0.2rem;
    font-weight: bold;
  }

  .content-icons {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }

  .content-icons span {
    font-size: calc(1rem + 0.4vw);
    cursor: pointer;

    @media screen and (max-width: 2400px) {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const Hand = styled.div`
  display: flex;
  margin-top: 2rem;
  height: 100%;
  min-height: 72vh;
  max-height: 50rem;
  position: absolute;
  bottom: 0;
  left: 40%;
  @media screen and (max-width: 1536px) {
    display: none;
  }
`;

export const Login = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  min-height: 72vh;
  width: 30%;

  @media screen and (max-width: 1536px) {
    width: 50%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .ant-spin-nested-loading {
    width: 80% !important;
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: calc(1rem + 0.4vh);
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .button {
    font-size: calc(1rem + 0.4vw) !important;
    height: calc(2rem + 0.8vh) !important;
    cursor: pointer;

    @media screen and (max-width: 2400px) {
      font-size: 1rem !important;
      height: 2rem !important;
    }
  }

  .submitButton {
    background: #008241;
    border-radius: 50px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    width: 100%;
    font-weight: 500;
    transition: background-color 0.2s;
    font-weight: bold;
    &:hover {
      background: ${shade(0.2, '#008241')};
    }
  }

  .contentInput {
    width: 100%;
    position: relative;
    border: 1px solid #adadad;
    border-radius: 10px;

    .ant-input {
      height: calc(2rem + 0.8vh) !important;
      font-size: calc(1rem + 0.4vw) !important;
      @media screen and (max-width: 2400px) {
        font-size: 1rem !important;
        height: 30px !important;
      }
    }

    .ant-input-prefix {
      display: flex !important;
      align-items: center !important;
      margin-right: 10px;
    }

    .ant-input-prefix svg {
      font-size: calc(1rem + 0.4vw);
      @media screen and (max-width: 2400px) {
        font-size: 15px !important;
      }
    }

    .ant-input-suffix {
      align-items: center;
      display: flex;
    }

    .ant-input-suffix svg {
      font-size: calc(1rem + 0.4vw);
      @media screen and (max-width: 2400px) {
        font-size: 15px !important;
      }
    }

    .ant-input-affix-wrapper {
      width: 95% !important;
      border: none;
      outline: none;
      margin-left: 10px;
    }

    .ant-input-affix-wrapper {
      background-color: transparent !important;
      box-shadow: none !important;
      height: 45px !important;
    }

    input {
      border: none;
      outline: none;
    }
  }

  .infoTitle {
    width: 50%;
    padding: 0px 20px;
  }
  .infoTitle h2 {
    color: #008241;
    text-align: center;
  }

  .infoTitle p {
    color: #6d6d6d;
    font-size: 0.8rem;
    text-align: center;
  }

  .rowIcones {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .rowIcones p {
    cursor: pointer;
  }

  .rowIcones h3 {
    margin-top: 10px;
  }

  /* @media (max-width: 600px) {
    flex-direction: column-reverse;

    .infoTitle {
      width: 90%;
      padding: 0px 20px;
    }

    .infoTitle p {
      text-align: justify;
    }

    .rowIcones {
      width: 100%;

      margin-top: 20px;
      margin-bottom: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .rowIcones h3 {
      width: 70%;
      text-align: center;
    }
  } */

  .login-about {
    color: #fff;
    text-align: justify;
    font-size: calc(1rem + 0.4vw);
    width: 80%;

    @media screen and (max-width: 2400px) {
      font-size: 1rem;
    }
  }

  .login-about h2 {
    color: #fff;
  }

  .img-govern {
    width: 40%;

    img {
      width: 100%;
    }
  }

  .login-icons {
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    display: none;
    flex-wrap: wrap;
    gap: 0.5rem;
    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }

  .login-icons span {
    font-size: calc(1rem + 0.4vw);
    cursor: pointer;

    @media screen and (max-width: 2400px) {
      font-size: 1rem;
    }
  }
`;

export const PasswordInput = styled(Input.Password)`
  .wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #adadad;
    border-radius: 35px;

    .ant-input {
      height: 37px !important;
    }

    .ant-input-prefix {
      display: flex !important;
      align-items: center !important;
    }

    .ant-input-suffix {
      align-items: center;
      display: flex;
    }

    .ant-input-affix-wrapper {
      width: 95% !important;
      border: none;
      outline: none;
      margin-left: 10px;
    }

    .ant-input-affix-wrapper {
      background-color: transparent !important;
      box-shadow: none !important;
      height: 45px !important;
    }

    input {
      border: none;
      outline: none;
    }
  }
`;

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px);


  }
  to{
    opacity: 1;
    transform: translateX(0)
  }`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  animation: ${appearFromLeft} 1s;
  form {
    /* margin: 60px 20px; */
    padding: 2rem calc(2rem + 0.4vw) 2rem calc(2rem + 0.4vw);
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    border: 1px solid #cecece;
    background: #fff;
    text-align: center;
    font-size: calc(1rem + 0.4vw);

    @media screen and (max-width: 2400px) {
      font-size: 1rem;
    }
    h1 {
      /* margin-bottom: 10px; */
      text-align: left;
      color: #3d3d3d;
    }
    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  .input {
    border: none !important;
  }

  .row {
    text-align: left;
  }

  .row h1 {
    padding-left: 10px;
    color: #008241;
    font-weight: 500;
    font-size: 2.5rem;
    margin-bottom: 0;
  }

  .row h3 {
    padding-left: 10px;
    color: #616161;
    line-height: 0%;
  }
  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }

  .containerImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .containerImg img {
    padding-top: 20px;
    width: 60%;
  }

  @media (max-width: 600px) {
    .containerImg img {
      width: 200px;
    }
    .from {
      padding: 15% 8%;
    }

    .row {
      display: block;
    }

    .row h1 {
      padding-left: 10px;
      color: #008241;
      font-weight: 500;
      font-size: 2rem;
    }
  }
`;

export const Rodape = styled.div`
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0;
  left: 0;
  font-size: 10pt;
  z-index: 10;
  border: 1px solid #f00;
`;

export const LoginSpin = styled(Spin)`
  .ant-spin-nested-loading {
    width: 100% !important;
    max-width: 340px !important;
    background: red !important;
  }
`;

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react';
import {
  ExportOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Input, Button as ButtonAntd, Form, Modal, Spin } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import Button from '../../components/button';
import comunication from '../../assets/comunication.svg';
import perguntas from '../../assets/perguntasFrequentes.svg';
import AlertaCelular from '../../assets/AlertaCelularLogo.svg';
import backgroundImg from '../../assets/BG_MeuCelular.png';
import checkIcon from '../../assets/check.png';
import faqIcon from '../../assets/duvida_frequente.png';
import lawIcon from '../../assets/falsa_comunication.png';
import logoWhiteText from '../../assets/logoAlerta.png';
import HandImg from '../../assets/celular na mão.png';
import governoIcon from '../../assets/governo.svg';

import {
  Container,
  StepByStep,
  Hand,
  Login,
  AnimationContainer,
  LoginSpin,
} from './styles';
import { toast } from 'react-toastify';
import { AuthService } from 'services/auth';
import { RecoverPasswordModal } from 'components/modals/recuperar-senha';
import { DEFAULT_ERROR_MESSAGE } from 'utils/defaultMessages';
import { Art340Modal } from 'components/modals/art340';
import externalLinkImg from '../../assets/link-externo-green.png';

function LoginWrapper() {
  const { signIn } = useAuth();
  const { validateCpf, validateEmail, checkCode } = AuthService();
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [recoverEmailModalForm] = Form.useForm();
  const [validationModalForm] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingValidateUserModal, setLoadingValidateUserModal] =
    useState(false);
  const [isRequireAccessModalOpen, setIsRequireAccessModalOpen] =
    useState(false);
  const [isModalValidateUserOpen, setIsModalValidateUserOpen] = useState(false);
  const [isModalRecoverEmailOpen, setIsModalRecoverEmailOpen] = useState(false);
  const [isModalArt340Open, setIsModalArt340Open] = useState(false);
  const [userAlreadyHasAccess, setUserAlreadyHasAccess] = useState(false);
  const [encodedEmail, setEncodedEmail] = useState();

  const [isUserCPFValid, setIsUserCPFValid] = useState(false);

  const navigate = useNavigate();

  const cpfMask = value => {
    setUserAlreadyHasAccess(false);

    const auxCpfMasked = value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    modalForm.setFieldsValue({
      cpf: auxCpfMasked,
    });
    form.setFieldsValue({
      cpf: auxCpfMasked,
    });
    recoverEmailModalForm.setFieldsValue({
      cpf: auxCpfMasked,
    });
  };

  const isValideCPF = cpf => {
    if (!cpf) {
      return false;
    }
    // Remover caracteres não numéricos do CPF
    cpf = cpf.replace(/\D/g, '');

    // Verificar se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verificar se todos os dígitos são iguais, o que é um CPF inválido
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    // Calcular os dígitos verificadores
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let primeiroDigito = 11 - (soma % 11);
    if (primeiroDigito > 9) {
      primeiroDigito = 0;
    }

    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    let segundoDigito = 11 - (soma % 11);
    if (segundoDigito > 9) {
      segundoDigito = 0;
    }

    // Verificar se os dígitos verificadores calculados são iguais aos dígitos fornecidos
    if (
      parseInt(cpf.charAt(9)) !== primeiroDigito ||
      parseInt(cpf.charAt(10)) !== segundoDigito
    ) {
      return false;
    }

    return true;
  };

  const cpfValidation = (_, value) => {
    if (value && isValideCPF(value)) {
      setIsUserCPFValid(true);
      return Promise.resolve();
    }
    setIsUserCPFValid(false);
    if (value) {
      return Promise.reject('CPF inválido');
    }
    return Promise.reject('Obrigatório');
  };

  const handleSubmit = useCallback(async values => {
    try {
      let cpf = values.cpf;
      let password = values.password;

      const hasValue = Object.keys(values).some(v => values[v]);
      if (hasValue) {
        setloading(true);

        const patt1 = /[0-9]/g;
        cpf = cpf.toString();
        password = password.toString();
        cpf = cpf.match(patt1).join('');

        const loginResponse = await signIn({ cpf, password });
        if (loginResponse === 'isAuthenticated') {
          navigate('/home');
        } else if (loginResponse === 'isOnly190User') {
          toast.warning(
            'Usuário não possui acesso ao sistema. Solicite acesso para se cadastrar na plataforma'
          );
        }
      } else {
        throw new Error('CPF ou Senha inválidos');
      }
    } catch (err) {
      toast.error(DEFAULT_ERROR_MESSAGE);
      console.error(err);
    } finally {
      setloading(false);
    }
  }, []);

  const onValidateUser = useCallback(async () => {
    try {
      setLoadingModal(true);

      const { cpf } = modalForm.getFieldsValue();
      const cpfFormatted = cpf.replaceAll('.', '').replaceAll('-', '');
      const res = await validateCpf(cpfFormatted);

      if (res?.data?.userExists) {
        setEncodedEmail(res?.data?.encodedEmail);
      } else if (res.data?.messageForUser) {
        if (
          res.data.messageForUser ===
          'Cadastro não foi concluído. Será necessário recadastrar suas informações.'
        ) {
          toast.info(res.data.messageForUser);
          navigate('/registrar-usuario', {
            state: { cpf: form.getFieldValue('cpf') },
          });
        }
        setUserAlreadyHasAccess(true);
      }
      setLoadingModal(false);
    } catch (err) {
      if (err === 'Usuário não cadastrado.') {
        navigate('/registrar-usuario', {
          state: { cpf: form.getFieldValue('cpf') },
        });
      } else {
        toast.error(DEFAULT_ERROR_MESSAGE);
        setLoadingModal(false);
      }
    }
  }, []);

  const onValidateEmail = useCallback(async () => {
    try {
      setLoadingModal(true);
      const { email } = modalForm.getFieldsValue();
      const { cpf } = modalForm.getFieldsValue();
      const cpfFormatted = cpf.replaceAll('.', '').replaceAll('-', '');
      const validateEmailResponse = await validateEmail(cpfFormatted, email);
      if (validateEmailResponse?.data?.messageForUser) {
        toast.success(validateEmailResponse?.data?.messageForUser);
        setIsModalValidateUserOpen(true);
      } else {
        toast.info(validateEmailResponse);
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingModal(false);
    }
  }, []);

  const onUserAcess = useCallback(async values => {
    try {
      setLoadingValidateUserModal(true);
      const body = {
        ...values,
        cpf: values.cpf.replaceAll('.', '').replaceAll('-', ''),
      };
      const checkCodeResponse = await checkCode(body);
      if (
        checkCodeResponse?.data?.messageForUser ===
        'A role foi adicionada  ao usuário'
      ) {
        toast.success('Acesso liberado');
        setIsModalValidateUserOpen(false);
        setIsRequireAccessModalOpen(false);
      } else {
        toast.info(checkCodeResponse);
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingValidateUserModal(false);
    }
  }, []);

  const onCloseRequireAccessModal = useCallback(() => {
    setIsRequireAccessModalOpen(false);
    setEncodedEmail();
    setUserAlreadyHasAccess(false);
  }, []);

  return (
    <Container
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
      }}
    >
      <Modal
        title="Solicitar Acesso"
        footer={null}
        onCancel={onCloseRequireAccessModal}
        destroyOnClose
        visible={isRequireAccessModalOpen}
      >
        <Spin spinning={loadingModal}>
          <Form form={modalForm} layout="vertical">
            <Form.Item
              label="CPF"
              name="cpf"
              rules={[
                {
                  validator: cpfValidation,
                },
              ]}
            >
              <Input
                placeholder="000.000.000-00"
                onChange={value => cpfMask(value.target.value)}
                disabled={encodedEmail}
              />
            </Form.Item>

            {userAlreadyHasAccess && (
              <p style={{ color: 'red' }}>
                Usuário já possui acesso ao sistema.
              </p>
            )}

            {encodedEmail && (
              <>
                <Form.Item
                  label="Email"
                  name="encodedEmail"
                  initialValue={encodedEmail}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Confirmar E-mail"
                  rules={[
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                    { type: 'email', message: 'Email informado inválido' },
                  ]}
                >
                  <Input type="email" placeholder="E-mail" />
                </Form.Item>

                <Button onClick={onValidateEmail}>Confirmar</Button>
              </>
            )}

            {!encodedEmail && (
              <Button
                disabled={!isUserCPFValid}
                style={!isUserCPFValid ? { background: 'grey' } : {}}
                onClick={
                  userAlreadyHasAccess
                    ? onCloseRequireAccessModal
                    : onValidateUser
                }
              >
                {userAlreadyHasAccess ? 'Fechar' : 'Confirmar'}
              </Button>
            )}
          </Form>
        </Spin>
      </Modal>
      <Modal
        title="Validação da conta"
        footer={null}
        onCancel={() => setIsModalValidateUserOpen(false)}
        visible={isModalValidateUserOpen}
        destroyOnClose
      >
        <Spin spinning={loadingValidateUserModal}>
          <Form
            form={validationModalForm}
            onFinish={onUserAcess}
            layout="vertical"
          >
            <Form.Item
              label="CPF"
              name="cpf"
              initialValue={modalForm.getFieldValue('cpf')}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="email"
              label="Confirmar E-mail"
              initialValue={modalForm.getFieldValue('email')}
            >
              <Input type="email" disabled />
            </Form.Item>

            <Form.Item
              label="Código"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Button onClick={validationModalForm.submit}>Confirmar</Button>
          </Form>
        </Spin>
      </Modal>
      <RecoverPasswordModal
        form={recoverEmailModalForm}
        isVisible={isModalRecoverEmailOpen}
        setIsVisible={setIsModalRecoverEmailOpen}
        cpfMask={cpfMask}
        isValideCPF={isValideCPF}
        haveCpfOnInput={isValideCPF(form.getFieldValue('cpf'))}
      />

      <Art340Modal
        isVisible={isModalArt340Open}
        setIsVisible={setIsModalArt340Open}
      />

      <StepByStep>
        <div className="content-logo">
          <img src={logoWhiteText} alt="SSPDS" />
        </div>
        <div className="text-header">
          <span style={{ marginRight: '0.2rem' }}>CADASTRE-SE E FACILITE</span>{' '}
          A RECUPERAÇÃO DO SEU <span>CELULAR</span> EM CASO DE{' '}
          <span>PERDA, ROUBO OU FURTO</span>
        </div>
        <div className="content-steps">
          <ul>
            <li>
              <img src={checkIcon} />
              <span>1. Cadastre seu celular a qualquer momento.</span>
            </li>
            <li>
              <img src={checkIcon} />
              <span>
                2. Em caso de perda, roubo ou furto, você pode criar um
                pré-alerta.{' '}
              </span>
            </li>
            <li>
              <img src={checkIcon} />
              <span>
                3. Após fazer e incluir o Boletim de Ocorrência, o alerta será
                criado para a polícia.{' '}
              </span>
            </li>
            <li>
              <img src={checkIcon} />
              <span>
                4. Pronto, as forças de segurança entram em ação para recuperar
                seu celular.
              </span>
            </li>
          </ul>
        </div>
        <div className="content-icons">
          <span onClick={() => navigate('/duvidas-frequentes')}>
            Dúvidas <img src={faqIcon} alt="FAQ.png" />
            <br /> Frequentes
          </span>
          <span onClick={() => setIsModalArt340Open(true)}>
            Art.340 <img src={lawIcon} alt="icon.png" />
            <br /> Falsa Comunicação
          </span>
        </div>
      </StepByStep>
      <Hand>
        <img src={HandImg} alt="hand.png" />
      </Hand>

      <Login>
        {/* <div className="infoTitle">
          <h2
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            Sobre o Meu Celular
            <img
              src={externalLinkImg}
              alt="sobre.png"
              style={{ width: '20px', height: '20px', cursor: 'pointer' }}
              onClick={() => navigate('/sobre')}
            />
            {/* <a href="https://www.flaticon.com/br/icones-gratis/fora" title="fora ícones">Fora ícones criados por Freepik - Flaticon</a> */}
        {/* <ExportOutlined onClick={() => navigate('/sobre')}/> */}
        {/* </h2>
          <p>
            É um Sistema desenvolvido pela COTIC/SSPDS - Coordenadoria de
            Tecnologia da Informação e Comunicação Secretário da Segurança
            Pública e Defesa Social do Estado do Ceará, e tem como objetivo
            reduzir crimes de roubos/furtos que envolvam aparelhos celulares.
            Aqui o Usuário irá cadastrar seu Celular, informando alguns dados
            pessoais e Notifica as Autoridade Policiais quando for vítima de
            Roubo ou Furto.
          </p> */}
        {/* <p>
            <b>
              Caso já tenha cadastro no App 190 Ceará, você pode solicitar
              acesso com o mesmo CPF no Meu Celular Ceará.
            </b>
          </p> */}
        {/* <div className="rowIcones">
            <span
              style={{ cursor: 'pointer' }}
              placeholder="Perguntas frequentes"
              role="button"
              tabIndex="0"
              onClick={() => navigate('/duvidas-frequentes')}
            >
              <img
                src={perguntas}
                alt="SSPDS"
                style={{ width: 90, height: 90 }}
              />

              <h3>Dúvidas frequentes</h3>
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setIsModalArt340Open(true)}
            >
              <img
                src={comunication}
                alt="SSPDS"
                style={{ width: 90, height: 90 }}
              />

              <h3>Art. 340 - Falsa comunicação</h3>
            </span>
          </div>
        </div> */}
        <Spin spinning={loading}>
          <AnimationContainer>
            <Form form={form} onFinish={handleSubmit}>
              <div className="row">
                <br />
                <h3>Bem-vindo ao</h3>
              </div>
              <div className="containerImg">
                <img src={AlertaCelular} alt="SSPDS" />
              </div>

              <Form.Item
                name="cpf"
                rules={[
                  {
                    validator: cpfValidation,
                  },
                ]}
              >
                <Input
                  onChange={value => cpfMask(value.target.value)}
                  placeholder="000.000.000-00"
                  className="contentInput"
                  prefix={<UserOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
              >
                <Input.Password
                  className="contentInput"
                  prefix={<LockOutlined style={{ fontSize: 15 }} />}
                  placeholder="Senha"
                  iconRender={visible =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <ButtonAntd className="button submitButton" htmlType="submit">
                Entrar
              </ButtonAntd>
              <Button
                className="button buttonCancelar"
                onClick={() => setIsRequireAccessModalOpen(true)}
              >
                Solicitar Acesso
              </Button>
              <Button
                className="button buttonRecSenha"
                onClick={() => setIsModalRecoverEmailOpen(true)}
              >
                Recuperar Senha
              </Button>
            </Form>
          </AnimationContainer>
        </Spin>
        <div className="login-about">
          <h2>Sobre o Meu Celular</h2>
          <p>
            É um sistema desenvolvido pela COTIC/SSPDS - Coordenadoria da
            Tecnologia da Informação e Comunicação da Secretaria da Segurança
            Pública e Defesa Social do Estado do Ceará, que tem como objetivo
            reduzir crimes de roubos/furtos que envolvam aparelhos celulares.
            Aqui o usuário irá cadastrar seu celular, informando alguns dados
            pessoais, e notificará as autoridade policiais, quando for vítima de
            roubo ou furto.
          </p>
        </div>
        <div className="img-govern">
          <img src={governoIcon} alt="governo.png" />
        </div>

        <div className="login-icons">
          <span onClick={() => navigate('/duvidas-frequentes')}>
            Dúvidas <img src={faqIcon} alt="FAQ.png" />
            <br /> Frequentes
          </span>
          <span onClick={() => setIsModalArt340Open(true)}>
            Art.340 <img src={lawIcon} alt="icon.png" />
            <br /> Falsa Comunicação
          </span>
        </div>
      </Login>
    </Container>
  );
}

export default LoginWrapper;
